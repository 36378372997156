import React from "react";
import styled from "styled-components";
import { colors, breakpoints, eases } from "../../styles/variables";

export const PurchaseSection = styled.section`
  color: #fff;
  background-color: ${colors.darkGreen};
  scroll-margin-top: 40px;

  .leftChar,
  .rightTopPixi,
  .rightPixi {
    position: absolute !important;
    z-index: 2;
    pointer-events: none;
  }

  .leftChar {
    left: 0;
    bottom: 0;
    width: 200px;
    transform: translate(calc(-100% + 21%), 14%);
  }

  .rightTopPixi {
    right: 0;
    top: 0;
    width: 280px;
    transform: translate(0%, -100%);
  }

  .rightPixi {
    right: 0;
    top: 0;
    width: 125px;
    transform: translate(100%, -5%);
  }

  &.withBackground {
    padding: 0;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    .gridContainer {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }
  &.extra-padding-top {
    padding-top: 64px;
  }
  .bgFade {
    padding: 0;
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 200px;
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(163, 82, 87, 1) 100%);
    background-size: cover;
  }
  .instruction {
    margin-top: 60px;
    margin-bottom: 25px;
  }
  .largeNumber {
    font-family: "ProximaNova-Bold";
    font-size: 72px;
    line-height: 10%;
    color: ${colors.orange};
    margin: 0 40px 0 0;
    vertical-align: text-top;
  }
  .consoleButtonRow {
    width: 95%;
    max-width: 340px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 16px;
    justify-content: center;
    padding-bottom: 14px;

    a {
      width: 100%;
      grid-column: span 6;
    }
  }
  .boxType {
    background-color: ${colors.lightGreen};
    grid-column: 1 / span 12;
    border: 4px solid ${colors.darkGreen};
    box-shadow: 8px 8px 0 ${colors.darkGreen};
    display: flex;
    flex-direction: column;
    padding: 72px;
  }
  .editionBox + .editionBox {
    margin-top: 60px;
  }

  .disclaimer {
    margin-top: 20px;
    p {
      margin-bottom: 0;
      text-align: center;
    }
  }

  // Overall layout
  .gridOut {
    min-height: 100%;
    padding-bottom: 7.5vw;
  }
  .gridIn {
    position: relative;
    grid-column: 1 / span 12;
    background: linear-gradient(0deg, rgba(179, 195, 53, 0.1) 0%, rgba(179, 195, 53, 0.1) 100%),
      linear-gradient(180deg, #354f38 0.01%, #4b6f49 100%);
    border: 1px solid ${colors.lightGreen};

    /* TODO: remove for pre-order */
    .editionWrapper {
      padding-top: 2rem;
    }
  }

  .layoutBox {
    width: 100%;
    grid-column: 1 / span 12;
    grid-row: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .slideBox {
    width: 100%;
    background-color: ${colors.orange};
    position: relative;
    margin-bottom: 64px;
  }

  // Tabs section
  .topNavDropdown {
    display: none;
  }
  .topNavTabs {
    display: block;
    margin-bottom: 50px;
    background-color: ${colors.midGreen};
    display: flex;
    align-items: center;
    justify-content: space-around;
    -webkit-justify-content: space-evenly !important;
    height: 50px;
    position: relative;

    .tabOuter {
      width: 16.66%;
      height: 100%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 4px solid ${colors.darkGreen};

      .tab-text {
        color: ${colors.darkGreen};
        transition: color 0.5s ease-out;
        font-size: 16px;
        line-height: 1.7;
        font-weight: normal;
        text-transform: uppercase;

        @media (max-width: ${breakpoints.tablet}) {
          font-size: 10px;
        }
      }
      &.active {
        .tab-text {
          color: ${colors.white};
        }
      }
    }
    .tabHighlight {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 16.66%;
      height: 4px;
      background-color: ${colors.white};
      transition: transform 0.5s ${eases.inOut};
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    .leftChar,
    .rightTopPixi,
    .rightPixi {
      display: none !important;
    }
    .instruction {
      margin-bottom: 25px;
    }
    .boxType {
      grid-column: 1 / span 8;
      padding: 32px;
    }
    .gridIn {
      height: 100%;
      border: none;
      background: none;

      /* TODO: release for pre-order release */
      .editionWrapper {
        padding-top: 0.6rem;
      }
    }
    .gridOut {
      padding-top: 36px;
    }
    .topNavDropdown {
      display: block;
      padding-bottom: 20px;
    }
    .topNavTabs {
      display: none;
    }
    &.extra-padding-top {
      padding-top: 0;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    scroll-margin-top: -20px;
    padding-bottom: 50px;

    .lightning {
      display: none !important;
    }
    .largeNumber {
      display: block;
      line-height: 100%;
      font-size: 56px;
    }
    .instruction {
      margin-bottom: 32px;
    }
    .consoleButtonRow {
      padding: 0px;
    }
    .boxType {
      grid-column: 1 / span 2;
      padding: 20px;
    }
    .gridIn {
      height: 100%;
      border: none;
      background: none;
    }
    .gridOut {
      padding-top: 32px;
    }

    .consoleButtonRow {
      > a {
        grid-column: span 6;
      }
    }
  }
`;
