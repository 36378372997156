import React, { MouseEventHandler, useEffect, useRef } from "react";
import styled from "styled-components";
import gsap from "gsap";
import { breakpoints, colors, eases } from "../../../styles/variables";

const ButtonContainer = styled.a`
  padding: 6px 10px;
  cursor: pointer;
  flex: 1;
  height: 80px;
  transition: all 0.2s ease-out;
  position: relative;
  animation: scale 0.2s linear 1;
  transform-origin: left;
  grid-column: span 3;

  // Default is blue theme
  border: 4px solid ${colors.white};
  box-shadow: 4px 4px ${colors.lightGreen};

  //Scales Platform containers on render
  @keyframes scale {
    0% {
      transform: scaleX(0.65);
    }

    100% {
      transform: scaleX(1);
    }
  }

  &.platformIcon-disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.button-group2 {
    min-width: 100%;
  }

  &.button-group3,
  &.button-group4 {
    min-width: 118px;
  }

  &.button-group5,
  &.button-group6 {
    min-width: 117px;
  }

  &.button-group6 {
    min-width: 20%;
    .background {
      background-size: 400% 100%;
    }

    &:nth-child(1),
    &:nth-child(2) {
      min-width: 45%;

      .background {
        background-size: 320% 100%;
      }
    }
  }

  .background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(30deg, ${colors.white} 50%, ${colors.darkGreen} 51%);
    background-size: 320% 100%;
    background-position: 0 0;
    transition: background-position 0.5s ${eases.inOut};
  }

  .logo,
  svg {
    max-width: 100%;
    height: 100%;
    margin: 0 auto;
    position: relative;
    opacity: 0;
    animation: logo 0.5s 1;
    animation-fill-mode: forwards;
    animation-delay: 0.3s;
  }
  //fade in logos after scale render
  @keyframes logo {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  path {
    fill: ${colors.darkGreen};
    transition: fill 0.3s ease-out;
  }

  &:hover,
  &.active {
    box-shadow: 2px 2px ${colors.lightGreen};
    transform: translate(2px, 2px);
    .background {
      background-position: 100% 0;
    }

    path {
      fill: ${colors.white};
    }
  }

  //Had to write a ton of media queries for desired design outcome. Always two rows, regardless of total number of platforms. Use case up to 6 platforms.
  @media (max-width: ${breakpoints.laptop}) {
    &.button-group3,
    &.button-group4 {
      min-width: 100%;
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    &.button-group3,
    &.button-group4 {
      min-width: 262px;
    }

    &.button-group5,
    &.button-group6 {
      min-width: 18%;

      &:nth-child(1),
      &:nth-child(2) {
        min-width: 45%;
        padding-left: 7%;
        padding-right: 7%;
      }
    }
  }

  @media only screen and (max-width: 560px) {
    &.button-group5,
    &.button-group6 {
      min-width: 45%;

      &:nth-child(1),
      &:nth-child(2) {
        min-width: 100%;
        padding-left: 15%;
        padding-right: 15%;
      }
    }
  }

  @media only screen and (max-width: 420px) {
    &.button-group5,
    &.button-group6 {
      min-width: 45%;

      &:nth-child(1),
      &:nth-child(2) {
        min-width: 100%;
        padding-left: 10%;
        padding-right: 10%;
      }
    }
  }

  @media only screen and (max-width: 859px) {
    &.button-group4 {
      min-width: 176px;
    }
  }

  @media only screen and (max-width: 822px) {
    &.button-group3 {
      min-width: 167px;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    /* width: 100px; */
    padding: 9px 0px;
    .background {
      background-size: 350% 100%;
    }
    .logo,
    svg {
      max-width: 92%;
    }
    &.button-group3,
    &.button-group4 {
      min-width: 208px;
    }
  }

  @media only screen and (max-width: 537px) {
    &.button-group4 {
      min-width: 150px;
    }
  }

  @media only screen and (max-width: 595px) {
    &.button-group3 {
      min-width: 150px;
    }
  }

  @media only screen and (max-width: 466px) {
    &.button-group3 {
      min-width: 107px;
    }
  }

  @media only screen and (max-width: 422px) {
    &.button-group4 {
      min-width: 126px;
    }
  }

  /* @media only screen and (max-width: 541px) {
    min-width: 132px;
  } */

  /* @media only screen and (max-width: 386px) {
    min-width: 126px;
  }

  @media (max-width: ${breakpoints.mobileS}) {
    min-width: 122px;
  } */
`;

interface Props {
  console: string;
  selected?: string;
  url: string;
  callback: Function;
  svg: string;
  buttonLength: number;
  tabData: string;
}

export default function ButtonConsole(props: Props) {
  const tab = props.tabData;
  const selfRef = useRef(null);
  const disabled = !props.url;

  const url = disabled ? "#" : props.url;
  const onClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (disabled) {
      e.preventDefault();
    }
  };
  const iconClass = disabled ? "platformIcon-disabled" : "platformIcon";
  const className = `${iconClass} button-group${props.buttonLength}`;

  const consoleSlug = props.console.toLowerCase().replace(/\W+/g, "-");
  const id = `platform-link-${consoleSlug}`;

  useEffect(() => {
    const q = gsap.utils.selector(selfRef);
    gsap.fromTo(q(".logo"), { opacity: 0 }, { opacity: 1, duration: 3 });
  }, []);

  return (
    <ButtonContainer
      href={url}
      onClick={onClick}
      data-tab={tab}
      target="_blank"
      id={id}
      className={className}
      ref={selfRef}
    >
      <div className="background"></div>
      <div className="logo" dangerouslySetInnerHTML={{ __html: props.svg }}></div>
    </ButtonContainer>
  );
}
